<template>
	<div>
		<el-dialog :draggable="true" width="50%" top="5vh" v-model="outerVisible" @close="Reset" title="查看详情">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormUser.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormUser.phone" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="发布内容">
						<span class="xx"></span>
						<el-input disabled clearable type="textarea" v-model="ruleForm.content" placeholder="">
						</el-input>
					</el-form-item>
					<el-form-item label="上传的图片">
						<ul class="imglist">
							<li v-for="(item,index) in srcList" :key="index">
								<el-image style="width: 100%; height: 100%"
									:preview-src-list="srcList"
									:src="item" />
							</li>
						</ul>
					</el-form-item>
					<el-form-item label="上传的视频">
						<video controls v-if="ruleForm.video">
							<source :src="ruleForm.video" type="video/mp4">
							<source src="shanghai.ogg" type="video/ogg">
						</video>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/opinion";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {},
				ruleFormUser: {},
				srcList:[]
			}
		},
		methods: {
			// 获取信息
			getinfo(id) {
				this.$http.post(api.info, {
						id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.ruleFormUser = res.data.user
							if(res.data.pics) {
								this.srcList = res.data.pics.split(",")
							}
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.getinfo(id)
			}
		}
	}
</script>

<style scoped lang="scss">
	video{
		width: 300px;
		height: 250px;
	}
	.imglist{
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		li{
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>
